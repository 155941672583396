<script>
  import { mdiSendOutline } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import ConstructionRecordEditable from "../ConstructionRecordEditable.vue";

  export default defineComponent({
    components: {
      ConstructionRecordEditable
    },
    setup() {
      return {
        icons: {
          mdiSendOutline
        }
      };
    }
  });
</script>

<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <construction-record-editable></construction-record-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card class="mb-6">
        <v-card-text>
          <v-btn color="primary" class="mb-3" block>
            <v-icon class="me-2" left>
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Traffic Statement</span>
          </v-btn>
          <v-btn class="mb-3" color="secondary" block outlined> Preview </v-btn>
          <v-btn color="secondary" block outlined> Save </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
